import urljoin from "url-join";

const uploaderConfig = (apiRoot) => {
  const token = document.querySelector("[name=csrf-token]").content;
  return {
    uploader: {
      url: urljoin(apiRoot, 'contents/static_files/jodit_upload'),
      imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
      headers:{
        'X-CSRF-TOKEN': token,
      },
    },
  }
};

const defaultButtons = [
  "bold",
  "italic",
  "underline",
  "|",
  "ul",
  "ol",
  "|",
  "font",
  "fontsize",
  "brush",
  "paragraph",
  "|",
  "left",
  "center",
  "right",
  "justify",
  "|",
  "table",
  "link",
  "|",
  "undo",
  "redo",
  "|",
  "source",
]



export {
  uploaderConfig,
  defaultButtons
}
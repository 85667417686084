import React, { useCallback, useContext, useEffect, useState } from "react";

import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import ContentFrame from "@shared/ContentFrame";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";
import UiToggle from "@shared/UiToggle";

import TicketingPackageAssignmentParticipantTable from "./participant_packages/TicketingPackageAssignmentParticipantTable";
import TicketingParticipantPackagesTable from "./participant_packages/TicketingParticipantPackagesTable";

const TicketingPackageAssignment = () => {
  const { id } = useParams();
  const { apiRoot, rootUrl } = useContext(EventContext).values;
  const queryClient = useQueryClient();
  const history = useHistory();

  const [packageType, setPackageType] = useState(null);
  // const [ assignedPackages, setAssignedPackages ] = useState([]);
  const [packageTickets, setPackageTickets] = useState([]);
  const [showAssignedDetails, setShowAssignedDetails] = useState(true);
  const [showAvailableDetails, setShowAvailableDetails] = useState(true);

  const participantQuery = useQuery({
    queryKey: ["participants"],
    staleTime: 10000, // 10 seconds
    cacheTime: 36000, // 10 minutes
    queryFn: ({ signal }) =>
      axios
        .get(urljoin(apiRoot, "/participants/table"), { signal })
        .then((res) => res.data)
        .catch((error) => {
          alertHttpError(error);
        })
  });

  const individualQuery = useQuery({
    queryKey: ["individuals"],
    staleTime: 10000, // 10 seconds
    cacheTime: 36000, // 10 minutes
    queryFn: ({ signal }) =>
      axios
        .get(urljoin(rootUrl, "/-/individuals"), { signal })
        .then((res) => res.data)
        .catch((error) => {
          alertHttpError(error);
        })
  });

  const metaQuery = useQuery({
    queryKey: ["participantMeta"],
    staleTime: 10000, // 10 seconds
    cacheTime: 36000, // 10 minutes
    queryFn: ({ signal }) =>
      axios
        .get(urljoin(apiRoot, "/participants/meta"), { signal })
        .then((res) => res.data)
        .catch((error) => {
          alertHttpError(error);
        })
  });

  const refreshParticipants = useMutation({
    mutationFn: () => {
      return true;
    },
    onSuccess: () => {
      queryClient.invalidateQueries("participants");
      fetchParticipantPackages();
    }
  });

  const fetchPackage = useCallback(async () => {
    try {
      const result = await axios(urljoin(apiRoot, `ticketing/package_types/${id}`));
      setPackageType(result.data["package_type"]);
    } catch (error) {
      alertHttpError(error);
    }
  }, [apiRoot, id]);

  const fetchParticipantPackages = useCallback(async () => {
    try {
      const result = await axios(urljoin(apiRoot, `ticketing/package_types/${id}/participant_packages`));
      setPackageTickets(result.data["package_tickets"]);
    } catch (error) {
      alertHttpError(error);
    }
  }, [apiRoot, id]);

  useEffect(() => {
    fetchPackage();
    fetchParticipantPackages();
  }, [fetchParticipantPackages, fetchPackage]);

  const assignPackage = (participant_id) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, `/ticketing/package_types/${packageType.id}/assign_package`),
      method: "POST",
      data: {
        event_participant_id: participant_id
      }
    })
      .then((response) => {
        if (response.data.error == null) {
          alertSuccess("Package Assigned");
          setPackageTickets(response.data["package_tickets"]);
        } else {
          alertError(`Unable to assign package: ${response.data.error}`);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const unassignPackage = (participant_ticket_id, participant_id) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    axios({
      url: urljoin(apiRoot, `/ticketing/package_types/${packageType.id}/unassign_package`),
      method: "POST",
      data: {
        participant_ticket_id: participant_ticket_id,
        event_participant_id: participant_id
      }
    })
      .then((response) => {
        if (response.data.error == null) {
          alertSuccess("Package Removed");
          setPackageTickets(response.data["package_tickets"]);
        } else {
          alertError(`Unable to remove ticket: ${response.data.error}`);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const unassignTicket = (participant_ticket_id) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, `/ticketing/participant_tickets/${participant_ticket_id}`),
      method: "DELETE"
    })
      .then((response) => {
        if (response.data.error == null) {
          alertSuccess("Ticket Removed");
        } else {
          alertError(`Unable to remove ticket: ${response.data.error}`);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const columns = [
    {
      field: "name_first",
      headerName: "First Name",
      flex: 1
    },
    {
      field: "name_last",
      headerName: "Last Name",
      flex: 1
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1
    },
    {
      field: "company",
      headerName: "Company",
      flex: 1,
      sortComparator: (a, b) => {
        const stringA = a.company ? a.company.trim() : "";
        const stringB = b.company ? b.company.trim() : "";
        return stringA.localeCompare(stringB);
      }
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      renderCell: (params) => <span className="capitalize">{params.value}</span>
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => <span className="capitalize">{params.value}</span>
    },
    {
      headerName: "Test?",
      field: "test_flag",
      flex: 1,
      renderCell: (value) => {
        if (value) {
          return <div style={{ textAlign: "left", paddingLeft: "8px" }}>✓</div>;
        }
        return <div style={{ textAlign: "left", paddingLeft: "8px" }} />;
      },
      sortComparator: (a, b) => {
        const aFlag = a.test_flag ? 1 : 0;
        const bFlag = b.test_flag ? 1 : 0;
        return aFlag - bFlag;
      }
    },
    {
      field: "tags",
      headerName: "Tags",
      flex: 1
    }
  ];

  const getAssignedPackages = () => {
    if (packageTickets[0] && packageTickets[0].ticket_type && packageType) {
      return packageTickets[0].ticket_type.participant_tickets.filter(
        (pt) => pt.ticketing_package_type_id === packageType.id
      );
    }
    return [];
  };

  const renderBody = () => {
    if (participantQuery.isPending || individualQuery.isPending || metaQuery.isPending) {
      return <Loading />;
    }

    if (participantQuery.error || individualQuery.error || metaQuery.error) {
      return <div>Error loading data. Please refresh and try again.</div>;
    }

    return (
      <>
        <PageHeader text={`${packageType ? packageType.name : ""} Assignments`} />
        <div>
          <a className="sg-mgmt-link" onClick={() => history.push("/ticketing")}>
            Return to Tickets Overview
          </a>
        </div>
        <div className="mt-4">
          <UiToggle
            flag={showAssignedDetails}
            label="Assigned Participants"
            toggleFunc={() => {
              setShowAssignedDetails(!showAssignedDetails);
            }}
          />
          {showAssignedDetails ? (
            <TicketingParticipantPackagesTable
              assignPackage={assignPackage}
              columns={columns}
              fetchParticipantPackages={fetchParticipantPackages}
              metadataFields={metaQuery.data.metadataFields}
              individuals={individualQuery.data.individuals}
              participants={participantQuery.data.participants}
              participantPackages={getAssignedPackages()}
              refreshParticipants={refreshParticipants}
              regFields={metaQuery.data.regFields}
              tags={metaQuery.data.tags}
              types={metaQuery.data.types}
              unassignPackage={unassignPackage}
            />
          ) : (
            <></>
          )}
        </div>
        <div>
          <UiToggle
            flag={showAvailableDetails}
            label="Available Participants"
            toggleFunc={() => {
              setShowAvailableDetails(!showAvailableDetails);
            }}
          />
          {showAvailableDetails ? (
            <TicketingPackageAssignmentParticipantTable
              assignPackage={assignPackage}
              columns={columns}
              individuals={individualQuery.data.individuals}
              metadataFields={metaQuery.data.metadataFields}
              participants={participantQuery.data.participants}
              packageTickets={packageTickets}
              refreshParticipants={refreshParticipants}
              regFields={metaQuery.data.regFields}
              tags={metaQuery.data.tags}
              types={metaQuery.data.types}
              packageType={packageType}
              unassignPackage={unassignPackage}
              unassignTicket={unassignTicket}
            />
          ) : (
            <></>
          )}
        </div>
      </>
    );
  };

  return <ContentFrame>{renderBody()}</ContentFrame>;
};

export default TicketingPackageAssignment;
